<template>
  <div>
    <h1>Welkom</h1>

    <template v-if="postsResult">
      <article v-for="post in postsResult.posts" :key="post.id">
        <h2>{{ post.title }}</h2>
        <div v-html="post.formattedBody"></div>
        <time>Gepubliceerd op {{ formattedDate(post) }}.</time>
      </article>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  name: 'Dashboard',

  setup() {
    const AllPosts = `
      query {
        posts {
          id
          title
          formattedBody
          publishAt
        }
      }
    `;

    const { data: postsResult } = useQuery({ query: AllPosts, cachePolicy: 'network-only' });
    const formattedDate = (post) => {
      const datePattern = /^(\d{4})-(\d{2})-(\d{2})\s(\d{1,2}):(\d{1,2}):(\d{1,2})$/;
      const [, year, month, day] = datePattern.exec(post.publishAt);
      return `${day}-${month}-${year}`;
    };

    return { postsResult, formattedDate };
  },
});
</script>

<style lang="scss" scoped>
article {
  border: #ccc 1px solid;
  margin-bottom: 20px;
  max-width: 600px;
  padding: 20px;

  div::v-deep p:last-child {
    margin-bottom: 0;
  }

  time {
    border-top: #ccc 1px dotted;
    display: block;
    font-size: 13px;
    margin-top: 10px;
    padding-top: 10px;
  }
}
</style>
